import { Pie, mixins } from "vue-chartjs";
import _ from "lodash";

export default {
  name: "pie-chart",
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  data() {
    return {
      ctx: null
    };
  },

  mounted() {
    // first time data is changed to render the chart
    // after than, the built in mixins.reactiveProp handles the updates
    this.$watch(
      "chartData",
      (newVal, oldVal) => {
        if (!oldVal) {
          this.renderChart(this.chartData, this.options);
        }
      },
      { immediate: true }
    );
    // re-render is properties change on new values, eg on page resize,
    // Debouced so not called continually on screen resize
    this.$watch("options", _.debounce(this.checkRerenderChart, 300), { immediate: true });
  },
  methods: {
    checkRerenderChart(newVal, oldVal) {
      if (oldVal) this.renderChart(this.chartData, this.options);
    }
  }
};
