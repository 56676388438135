<template>
  <div>
    <card-component :title="chartTitle" icon="finance">
      <div v-if="auditchart.chartData" class="chart-area">
        <bar-chart style="height: 100%;" chart-id="auditchart" :chart-data="auditchart.chartData" :options="auditchart.extraOptions" />
      </div>
    </card-component>
  </div>
</template>

<script>
import audits from "@/enums/kpi/audits";
import dates from "@/common/dates";

import * as chartConfig from "@/components/Charts/chart.config";
import CardComponent from "@/components/CardComponent";
import BarChart from "@/components/Charts/BarChart";
import formatting from "../../common/formatting";

export default {
  components: { CardComponent, BarChart },
  props: {
    answers: { type: Array, required: true },
    auditType: { type: Number, required: true },
    location: { type: String, required: true },
    month: { type: Number, required: true },
    showSections: { type: Boolean, required: true },
    labels: { type: Array, required: true }
  },
  data() {
    return {
      auditchart: {
        chartData: null,
        extraOptions: {
          ...chartConfig.chartOptionsMain,
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent"
                },
                ticks: {
                  stepSize: 1,
                  callback: function(value, index, values) {
                    return audits.ScoreTypeLookup[value - 1]; // take one of score as, one was added to get bar for zero
                  },
                  min: 0
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: tooltipItem => {
                return audits.ScoreTypeLookup[tooltipItem.value - 1]; // take one of score as, one was added to get bar for zero
              },
              title: tooltipItem => "Question " + tooltipItem[0].label
            }
          }
        }
      }
    };
  },
  computed: {
    chartTitle() {
      return `${this.location} ${audits.AuditTypeLookup[this.auditType]} Data for ${dates.monthName(this.month)}`;
    }
  },
  watch: {
    answers() {
      this.setChartData();
    },
    showSections() {
      this.setChartData();
    }
  },
  methods: {
    setChartData() {
      // Issue with chart first displayed on hidden tab, so only update if tab/section active
      if (!this.showSections) return;

      if (this.answers) {
        const valueRelatedColors = this.answers.map(d => audits.ScoreColour[formatting.round(d, 0)]);
        this.auditchart.chartData = {
          datasets: [
            {
              ...chartConfig.defaultDataSetOptions,
              data: this.answers,
              backgroundColor: valueRelatedColors,
              // setting new dataset label results in new chart and other options being lost
              // possible could modify the mixin - https://github.com/apertureless/vue-chartjs/blob/develop/src/mixins/index.js
              label: "Question Scores"
            }
          ],
          labels: this.labels
        };
      }
    }
  }
};
</script>
