<template>
  <div>
    <hero-bar :has-right-visible="false">
      KPI
    </hero-bar>
    <div class="container mt-4">
      <div class="columns">
        <div class="column">
          <b-field label="Location" horizontal>
            <b-select placeholder="Select location" v-model="location">
              <option v-for="option in locationsAll" :value="option" :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Month" horizontal>
            <b-select placeholder="Select month" v-model="month">
              <option v-for="option in months" :value="option" :key="option">
                {{ monthName(option) }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>

    <section class="section is-main-section">
      <b-tabs position="is-centered" v-model="activeTab">
        <b-tab-item label="Audits">
          <AuditSummary
            :month="month"
            :location="location"
            :locations="locations"
            :auditType="auditType"
            :activeTab="activeTab"
            :activeTabs="activeTabs"
            @changeLocation="changeLocation"
          />
        </b-tab-item>

        <b-tab-item label="Actions">
          <AuditActions
            :auditType="auditType"
            :month="month"
            :location="location"
            :locations="locations"
            :activeTab="activeTab"
            :activeTabs="activeTabs"
            @changeLocation="changeLocation"
          />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import audits from "@/enums/kpi/audits";
import HeroBar from "@/components/HeroBar";
import AuditSummary from "@/components/kpi/AuditSummary.vue";
import AuditActions from "@/components/kpi/AuditActions.vue";

import arrays from "@/common/arrays";
import dates from "@/common/dates";

import _ from "lodash";

export default {
  name: "Home",
  components: {
    AuditSummary,
    HeroBar,
    AuditActions
  },
  data() {
    var queryMonth = Number(this.$route.query.month);
    if (isNaN(queryMonth) || queryMonth < 0 || queryMonth > 11) queryMonth = dates.lastMonth();

    return {
      activeTab: 0,
      activeTabs: { Audits: 0, Actions: 1 },
      location: audits.AllLocations,
      locations: [
        "Athlone",
        "Belfast",
        "Castlebar",
        "Cork",
        "Dublin",
        "Dundalk",
        "Galway",
        "Kilkenny",
        "Limerick",
        "Monaghan",
        "Mullingar",
        "Roscommon",
        "Sligo"
      ],
      month: queryMonth,
      months: _.range(12),
      auditType: 0
    };
  },
  computed: {
    locationsAll() {
      return arrays.addValueStart(this.locations, audits.AllLocations);
    }
  },
  watch: {
    month(newValue) {
      // this updates the url, but not the history
      this.$router.replace({ query: { month: newValue } });
    }
  },
  methods: {
    monthName(month) {
      return dates.monthName(month);
    },
    changeLocation(index) {
      this.location = this.locations[index];
    }
  }
};
</script>
