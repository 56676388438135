import { Bar, mixins } from "vue-chartjs";

export default {
  name: "bar-chart",
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  data() {
    return {
      ctx: null
    };
  },
  mounted() {
    this.$watch(
      "chartData",
      (newVal, oldVal) => {
        if (!oldVal) {
          this.renderChart(this.chartData, this.options);
        }
      },
      { immediate: true }
    );
  }
};
