<template>
  <div>
    <card-component :title="tableTitle" icon="format-list-numbered">
      <b-table :loading="isLoading" :row-class="getRowStyle" default-sort="name" :data="questions">
        <b-table-column label="No." field="id" sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column label="Question" field="question" sortable v-slot="props">
          {{ props.row.question }}
        </b-table-column>
        <template v-slot:empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="isLoading">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large" />
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </template>
      </b-table>
    </card-component>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";

export default {
  components: { CardComponent },
  props: {
    questions: { type: Array, required: true },
    answers: { type: Array, required: true },
    sectionName: { type: String }
  },
  data() {
    return {
      isLoading: false // todo
    };
  },
  computed: {
    tableTitle() {
      return this.sectionName + " Questions";
    }
  },

  methods: {
    getRowStyle(row, index) {
      const answer = this.answers[index];
      if (answer >= 2.5) return "backgroundComplianceFull";
      if (answer >= 1.5) return "backgroundCompliancePartial";
      if (answer > 0) return "backgroundComplianceNone";
      return "";
    }
  }
};
</script>
