<template>
  <div>
    <div v-show="showSections">
      <div class="columns">
        <div class="column is-2">
          <b-select class="mb-3" placeholder="Section" v-model="auditType">
            <option v-for="option in auditTypeIndex" :value="option" :key="option">
              {{ auditTypes[option] }}
            </option>
          </b-select>

          <PieCompliance :pieData="selectedPieData" :labels="labels" :shownPecentage="true" />

          <div class="has-text-centered mt-4">
            <b-button type="is-primary" icon-left="arrow-left-circle" @click="backToSummary">
              Back to Summary
            </b-button>
          </div>
        </div>

        <AuditSection
          class="column"
          :auditType="auditType"
          :month="month"
          :location="location"
          :showSections="showSections"
          :labels="questionsLabels"
          :answers="answersForMonth"
        />
      </div>
      <div class="columns">
        <div class="column">
          <card-component :title="prev6MonthTitle" icon="finance" header-icon="reload" @header-icon-click="getChartData">
            <div v-if="sectionTrend.chartData" class="chart-area">
              <line-chart
                style="height: 100%;"
                chart-id="sectionTrend"
                :chart-data="sectionTrend.chartData"
                :options="sectionTrend.extraOptions"
              />
            </div>
          </card-component>
        </div>
        <AuditQuestions class="column" :questions="questions" :answers="answersForMonth" :sectionName="currenetSection" />
      </div>
    </div>

    <div v-show="!showSections">
      <div class="columns is-centered" v-if="hasData">
        <PieCompliance class="column is-2" :pieData="combinedData" title="Overall" :labels="labels" :shownPecentage="true" />
        <PieCompliance
          class="column is-2"
          :pieData="pieData[auditTypeIndex.Customer]"
          title="Customer Files"
          @click="pieClick(auditTypeIndex.Customer)"
          :labels="labels"
          :shownPecentage="true"
        />
        <PieCompliance
          class="column is-2"
          :pieData="pieData[auditTypeIndex.Staff]"
          title="Staff Files"
          @click="pieClick(auditTypeIndex.Staff)"
          :labels="labels"
          :shownPecentage="true"
          :chartIndex="auditTypeIndex.Staff"
        />
        <PieCompliance
          class="column is-2"
          :pieData="pieData[auditTypeIndex.Office]"
          title="Office"
          @click="pieClick(auditTypeIndex.Office)"
          :labels="labels"
          :shownPecentage="true"
          :chartIndex="auditTypeIndex.Office"
        />
      </div>
      <div class="twoColumns">
        <card-component :title="prev6MonthTitle" icon="finance" header-icon="reload" @header-icon-click="getChartData">
          <div v-if="defaultChart.chartData" class="chart-area">
            <line-chart
              style="height: 100%;"
              chart-id="prev6Months"
              :chart-data="defaultChart.chartData"
              :options="defaultChart.extraOptions"
            />
          </div>
        </card-component>
      </div>
      <div class="twoColumns">
        <card-component :title="breakDownTitle" icon="finance" header-icon="reload" @header-icon-click="getChartData">
          <div v-show="isAllLocations" class="chart-area">
            <line-chart
              v-if="breakDownChart.chartData"
              chart-id="breakDown"
              :chart-data="breakDownChart.chartData"
              :options="breakDownChart.extraOptions"
            />
          </div>
          <div v-show="!isAllLocations" class="chart-area">
            <line-chart
              v-if="breakDownByTypeChart.chartData"
              style="height: 100%;"
              chart-id="breakdownByType"
              :chart-data="breakDownByTypeChart.chartData"
              :options="breakDownByTypeChart.extraOptions"
            />
          </div>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import audits from "@/enums/kpi/audits";
import * as chartConfig from "@/components/Charts/chart.config";
import CardComponent from "@/components/CardComponent";
import LineChart from "@/components/Charts/LineChart";
import PieCompliance from "@/components/Charts/PieCompliance.vue";
import AuditQuestions from "@/components/kpi/AuditQuestions.vue";
import AuditSection from "@/components/kpi/AuditSection.vue";

import formatting from "@/common/formatting";
import arrays from "@/common/arrays";
import dates from "@/common/dates";

import dayjs from "dayjs";

// Used for local data
import axios from "axios";

export default {
  name: "AuditSummary",
  components: {
    LineChart,
    PieCompliance,
    CardComponent,
    AuditSection,
    AuditQuestions
  },
  props: {
    month: { type: Number, required: true },
    location: { type: String, required: true },
    locations: { type: Array, required: true },
    activeTab: { type: Number, required: true },
    activeTabs: { type: Object, required: true }
  },
  data() {
    const extraOptions = {
      ...chartConfig.percentageChartOptions
    };

    var queryMonth = Number(this.$route.query.month);
    if (isNaN(queryMonth) || queryMonth < 0 || queryMonth > 11) {
      queryMonth = dayjs().month() - 1;
      if (queryMonth < 0) queryMonth = 11;
    }

    return {
      defaultChart: {
        chartData: null,
        extraOptions: extraOptions
      },
      breakDownChart: {
        chartData: null,
        extraOptions: {
          ...extraOptions,
          onClick: this.handleChartLocationClick
        }
      },
      breakDownByTypeChart: {
        chartData: null,
        extraOptions: extraOptions
      },
      sectionTrend: {
        chartData: null,
        extraOptions: extraOptions
      },
      pieData: [[]],
      selectedPieData: [],

      dataUrl: this.$router.options.base + "data-sources/audit.json",
      auditResults: [],
      auditTypes: ["Customer", "Staff", "Office"],
      auditTypeIndex: audits.AuditTypes,
      hasData: false,
      labels: arrays.removeLastValue(audits.ScoreTypeLookup),
      showSections: false,
      auditType: 0,

      questions: [],
      answers: []
    };
  },
  computed: {
    isAllLocations() {
      return this.location === audits.AllLocations;
    },
    combinedData() {
      var combined = [];
      for (var i = 0; i < this.pieData[0].length; i++) {
        combined.push(formatting.round((this.pieData[0][i] + this.pieData[1][i] + this.pieData[2][i]) / 3)); // todo
      }
      return combined;
    },
    monthNums() {
      return dates.last6MonthNums(this.month);
    },
    monthLabels() {
      return dates.last6MonthNames(this.month);
    },

    prev6MonthTitle() {
      return this.location + " previous 6 months";
    },
    breakDownTitle() {
      var month = dates.monthName(this.month);
      return this.location === audits.AllLocations ? `${month} Location Breakdown` : `${this.location} ${month} Breakdown`;
    },
    questionsLabels() {
      return this.questions.map(q => q.id);
    },
    answersForMonth() {
      let monthData = this.answers.filter(a => dates.sameMonth(a.date, this.month));

      if (this.location !== audits.AllLocations) {
        // filter by location also
        monthData = monthData.filter(a => a.location === this.location);
      }

      let answers = [];

      if (monthData.length) {
        answers = new Array(monthData[0].data.length).fill(0);

        // average for month
        monthData.forEach(a => {
          for (var i = 0; i < a.data.length; i++) {
            answers[i] += a.data[i];
          }
        });
        answers = answers.map(v => formatting.round(v / monthData.length));
      }
      return answers;
    },
    currenetSection() {
      return this.auditTypes[this.auditType];
    },
    auditLocationData() {
      // Filter by location only if not all locations
      if (this.location && this.location !== audits.AllLocations) {
        return this.auditResults.filter(d => {
          return d.location === this.location;
        });
      }
      // otherwise return all data
      return this.auditResults;
    }
  },
  watch: {
    location() {
      this.setChartData();
    },
    month() {
      this.setChartData();
    },
    activeTab() {
      this.setChartData();
    },
    showSections() {
      this.setChartData();
    },
    auditType(newValue) {
      this.selectedPieData = this.pieData[newValue];
      this.loadQuestions();
      this.loadAnswers();
      this.setTrendData();
    }
  },
  mounted() {
    this.getChartData();
    this.loadQuestions();
    this.loadAnswers();
  },
  methods: {
    handleChartLocationClick(evt, elements) {
      if (elements.length) {
        const index = elements[0] ? elements[0]._index : -1;
        this.$emit("changeLocation", index);
      }
    },
    async getChartData() {
      // Load JSON
      await axios.get(this.dataUrl).then(r => {
        this.isLoading = false;
        if (r.data && r.data.auditResults) {
          this.auditResults = r.data.auditResults;
          this.setChartData();
        }
      });
    },
    async loadQuestions() {
      const url = `${this.$router.options.base}data-sources/${audits.AuditTypeLookup[this.auditType]}.json`;
      await axios.get(url).then(r => {
        this.isLoading = false;
        if (r.data && r.data.questions) {
          this.questions = r.data.questions;
        }
      });
    },
    async loadAnswers() {
      const answersUrl = `${this.$router.options.base}data-sources/${audits.AuditTypeLookup[this.auditType]}Data.json`;
      await axios.get(answersUrl).then(r => {
        this.isLoading = false;
        if (r.data && r.data.answers) {
          this.answers = r.data.answers;
        }
      });
    },

    setChartData() {
      let chartData = null;

      if (this.location && this.location !== audits.AllLocations) {
        const locationData = this.auditResults.filter(d => {
          return d.location === this.location;
        });

        // Assume Data is alredy sorted - could used  list.sort((a, b) => (a.color > b.color) ? 1 : -1)
        if (locationData && locationData[0]) {
          // pie charts use last data for particular month
          const locationMonthData = locationData.filter(d => {
            return new Date(d.date).getMonth() === this.month;
          });
          if (locationMonthData && locationMonthData[0]) {
            chartData = locationMonthData[0];
          }
        }
      } else {
        // filter by month
        const monthData = this.auditResults.filter(d => {
          return new Date(d.date).getMonth() === this.month;
        });

        const overall = {
          data: [
            {
              type: "Customer",
              scores: [0, 0, 0]
            },
            {
              type: "Staff",
              scores: [0, 0, 0]
            },
            {
              type: "Office",
              scores: [0, 0, 0]
            }
          ]
        };
        const numAudits = monthData.length;
        if (numAudits) {
          for (var i = 0; i < monthData.length; i++) {
            overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Full] +=
              monthData[i].data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Full];
            overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Partial] +=
              monthData[i].data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Partial];
            overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.None] +=
              monthData[i].data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.None];

            overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Full] +=
              monthData[i].data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Full];
            overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Partial] +=
              monthData[i].data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Partial];
            overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.None] +=
              monthData[i].data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.None];

            overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Full] +=
              monthData[i].data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Full];
            overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Partial] +=
              monthData[i].data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Partial];
            overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.None] +=
              monthData[i].data[audits.AuditTypes.Office].scores[audits.ScoreTypes.None];
          }
          overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Full] = formatting.round(
            overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Full] / numAudits
          );
          overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Partial] = formatting.round(
            overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.Partial] / numAudits
          );
          overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.None] = formatting.round(
            overall.data[audits.AuditTypes.Customer].scores[audits.ScoreTypes.None] / numAudits
          );

          overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Full] = formatting.round(
            overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Full] / numAudits
          );
          overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Partial] = formatting.round(
            overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.Partial] / numAudits
          );
          overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.None] = formatting.round(
            overall.data[audits.AuditTypes.Staff].scores[audits.ScoreTypes.None] / numAudits
          );

          overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Full] = formatting.round(
            overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Full] / numAudits
          );
          overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Partial] = formatting.round(
            overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.Partial] / numAudits
          );
          overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.None] = formatting.round(
            overall.data[audits.AuditTypes.Office].scores[audits.ScoreTypes.None] / numAudits
          );

          chartData = overall;
        }

        // For line chart for ALL, we want to combine all overall, & targets across all audits for that month
        // This is handled in setLinetData where avertage for month is worked out
      }

      if (chartData) {
        this.pieData[audits.AuditTypes.Customer] = chartData.data[audits.AuditTypes.Customer].scores;
        this.pieData[audits.AuditTypes.Staff] = chartData.data[audits.AuditTypes.Staff].scores;
        this.pieData[audits.AuditTypes.Office] = chartData.data[audits.AuditTypes.Office].scores;

        // exclude the total score for specific location
        if (this.location !== audits.AllLocations) {
          this.pieData[audits.AuditTypes.Customer] = arrays.removeLastValue(this.pieData[audits.AuditTypes.Customer]);
          this.pieData[audits.AuditTypes.Staff] = arrays.removeLastValue(this.pieData[audits.AuditTypes.Staff]);
          this.pieData[audits.AuditTypes.Office] = arrays.removeLastValue(this.pieData[audits.AuditTypes.Office]);
        }

        this.hasData = true;
      } else {
        this.hasData = false;
      }

      // line graph
      if (this.activeTab === this.activeTabs.Audits) {
        if (this.showSections) {
          this.setTrendData();
        } else {
          this.setLinetData();
          this.setBreakdownData();
        }
      }
    },

    setLinetData() {
      const lineData = this.auditLocationData;
      // multiple records per month get averages (best for overall score across centres)
      var overallValues = [];
      var targetValues = [];
      this.monthNums.forEach(m => {
        var monthData = lineData.filter(d => new Date(d.date).getMonth() === m);
        // if more that one get average - assumes equal weighting
        // get totals
        var monthOverall = monthData.reduce((t, v) => t + v.overall, 0);
        var monthTarget = monthData.reduce((t, v) => t + v.target, 0);
        // then average
        if (monthData.length) {
          monthOverall = formatting.round(monthOverall / monthData.length);
          monthTarget = formatting.round(monthTarget / monthData.length);
        }
        overallValues.push(monthOverall);
        targetValues.push(monthTarget);
      });

      this.defaultChart.chartData = {
        datasets: [
          {
            ...chartConfig.defaultDataSetOptions,
            data: overallValues,
            label: "Actual"
          },
          {
            ...chartConfig.defaultDataSetOptions,
            borderColor: chartConfig.chartColors.default.info,
            pointBackgroundColor: chartConfig.chartColors.default.info,
            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
            data: targetValues,
            label: "Target"
          }
        ],
        labels: this.monthLabels
      };
    },
    setTrendData() {
      // trend result for particular selected audit section
      const lineData = this.auditLocationData;
      // multiple records per month get averages (for overall score across centres)
      var overallValues = [];
      this.monthNums.forEach(m => {
        var monthData = lineData.filter(d => new Date(d.date).getMonth() === m);
        // if more that one get average - assumes equal weighting
        // get totals
        var monthOverall = 0;
        monthData.forEach(a => {
          // get the section score for this audit (assume only one match)
          const section = a.data.filter(s => s.type === this.currenetSection)[0];
          monthOverall += section.scores[audits.ScoreTypes.Total];
        });
        // then average
        if (monthData.length) {
          monthOverall = formatting.round(monthOverall / monthData.length);
        }
        overallValues.push(monthOverall);
      });

      this.sectionTrend.chartData = {
        datasets: [
          {
            ...chartConfig.defaultDataSetOptions,
            data: overallValues,
            label: "Actual"
          }
        ],
        labels: this.monthLabels
      };
    },
    setBreakdownData() {
      const lineData = this.auditLocationData;

      var overallValues = [];
      var targetValues = [];

      // use data for particualr month
      const monthData = lineData.filter(d => {
        return new Date(d.date).getMonth() === this.month;
      });
      if (this.location === audits.AllLocations) {
        // group by location
        this.locations.forEach(l => {
          var locationData = monthData.filter(d => d.location === l);
          // if more that one get average - assumes equal weighting
          // get totals
          var monthOverall = locationData.reduce((t, v) => t + v.overall, 0);
          var monthTarget = locationData.reduce((t, v) => t + v.target, 0);
          // then average
          if (locationData.length) {
            monthOverall = formatting.round(monthOverall / locationData.length);
            monthTarget = formatting.round(monthTarget / locationData.length);
          }
          overallValues.push(monthOverall);
          targetValues.push(monthTarget);

          this.breakDownChart.chartData = {
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: overallValues,
                label: "Actual"
              },
              {
                ...chartConfig.defaultDataSetOptions,
                borderColor: chartConfig.chartColors.default.info,
                pointBackgroundColor: chartConfig.chartColors.default.info,
                pointHoverBackgroundColor: chartConfig.chartColors.default.info,
                data: targetValues,
                label: "Target"
              }
            ],
            labels: this.locations
          };
        });
      } else {
        // group by audit type
        var typeScores = [];
        this.auditTypes.forEach(t => {
          var typeScore = 0;
          monthData.forEach(a => {
            var auditScores = a.data.find(d => d.type === t);
            if (auditScores) typeScore += auditScores.scores[audits.ScoreTypes.Total];
          });
          typeScores.push(typeScore);
        });

        this.breakDownByTypeChart.chartData = {
          datasets: [
            {
              ...chartConfig.defaultDataSetOptions,
              data: typeScores,
              label: "Overall Score"
            }
          ],
          labels: this.auditTypes
        };
      }
    },
    pieClick(index) {
      this.auditType = index;
      this.selectedPieData = this.pieData[index];
      this.showSections = true;
    },
    backToSummary() {
      this.showSections = false;
    }
  }
};
</script>
