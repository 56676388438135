<template>
  <PieChart class="pieChart" :chart-data="fullChartData" :options="options" />
</template>

<script>
import PieChart from "@/components/Charts/PieChart";
import * as chartConfig from "@/components/Charts/chart.config";
import VueScreenSize from "vue-screen-size";

export default {
  name: "compliance-chart",
  components: { PieChart },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    pieData: Array,
    labels: Array,
    extraOptions: Object,
    title: String,
    shownPecentage: Boolean,
    chartIndex: { type: Number, required: false } // required if needing to handle click event
  },
  computed: {
    width() {
      return this.$vssWidth;
    },
    tooltipsCallBack() {
      // prettier-ignore
      return this.shownPecentage
        ? {
          callbacks: {
            label: (tooltipItem, data) => {
              return ` ${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}%`;
            }
          }
        }
        : {};
    },
    options() {
      return {
        legend: {
          display: this.$vssWidth < 769 || this.$vssWidth > 1200,
          position: "bottom",
          labels: {
            boxWidth: 10
          }
        },
        title: {
          display: !!this.title,
          text: this.title
        },
        tooltips: this.tooltipsCallBack,
        onClick: this.handleClick,
        ...this.extraOptions
      };
    },
    fullChartData() {
      return {
        datasets: [
          {
            data: this.pieData,
            backgroundColor: [
              chartConfig.chartColors.default.complianceNone,
              chartConfig.chartColors.default.compliancePartial,
              chartConfig.chartColors.default.complianceFull
            ]
          }
        ],
        labels: this.labels
      };
    }
  },
  methods: {
    handleClick(point, event) {
      const index = event[0] ? event[0]._index : -1;
      this.$emit("click", this.chartIndex, index);
    }
  }
};
</script>

<style>
.pieChart canvas {
  max-width: 200px !important;
  min-width: 170px !important;
  margin: auto;
}
</style>
