<template>
  <div>
    <div class="columns is-centered" v-if="hasData">
      <PieCompliance class="column is-2" :pieData="combinedData" title="Overall" :labels="labels" />
      <PieCompliance class="column is-2" :pieData="pieCustomerData" title="Customer Files" :labels="labels" />
      <PieCompliance class="column is-2" :pieData="pieStaffData" title="Staff Files" :labels="labels" />
      <PieCompliance class="column is-2" :pieData="pieOfficeData" title="Office" :labels="labels" />
    </div>

    <div :class="{ twoColumns: isAllLocations, oneColumn: !isAllLocations }">
      <card-component :title="prev6MonthTitle" icon="finance" header-icon="reload" @header-icon-click="getChartData">
        <div v-if="defaultChart.chartData" class="chart-area">
          <line-chart
            style="height: 100%;"
            chart-id="actionsPrev6Months"
            ref="actionsPrev6Months"
            :chart-data="defaultChart.chartData"
            :options="defaultChart.extraOptions"
          />
        </div>
      </card-component>
    </div>

    <div class="twoColumns" v-show="isAllLocations">
      <card-component :title="breakDownTitle" icon="finance" header-icon="reload" @header-icon-click="getChartData">
        <div class="chart-area">
          <line-chart
            v-if="breakDownChart.chartData"
            chart-id="breakDown"
            :chart-data="breakDownChart.chartData"
            :options="breakDownChart.extraOptions"
          />
        </div>
      </card-component>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import audits from "@/enums/kpi/audits";
import * as chartConfig from "@/components/Charts/chart.config";
import CardComponent from "@/components/CardComponent";
import LineChart from "@/components/Charts/LineChart";
import PieCompliance from "@/components/Charts/PieCompliance.vue";

import dates from "@/common/dates";

import dayjs from "dayjs";

// Used for local data
import axios from "axios";

export default {
  name: "AuditSummary",
  components: {
    LineChart,
    PieCompliance,
    CardComponent
  },
  props: {
    month: { type: Number, required: true },
    location: { type: String, required: true },
    locations: { type: Array, required: true },
    activeTab: { type: Number, required: true },
    activeTabs: { type: Object, required: true }
  },
  data() {
    const extraOptions = {
      ...chartConfig.chartOptionsMain
    };

    var queryMonth = Number(this.$route.query.month);
    if (isNaN(queryMonth) || queryMonth < 0 || queryMonth > 11) {
      queryMonth = dayjs().month() - 1;
      if (queryMonth < 0) queryMonth = 11;
    }

    return {
      defaultChart: {
        chartData: null,
        extraOptions: extraOptions
      },
      breakDownChart: {
        chartData: null,
        extraOptions: {
          ...extraOptions,
          onClick: this.handleChartLocationClick
        }
      },

      pieCustomerData: [],
      pieStaffData: [],
      pieOfficeData: [],

      dataUrl: this.$router.options.base + "data-sources/audit.json",
      auditResults: [],
      auditTypes: ["Customer", "Staff", "Office"],
      hasData: false,
      labels: audits.ActionStatusLookup
    };
  },
  computed: {
    isAllLocations() {
      return this.location === audits.AllLocations;
    },
    combinedData() {
      var combined = [];
      for (var i = 0; i < this.pieCustomerData.length; i++) {
        combined.push(this.pieCustomerData[i] + this.pieStaffData[i] + this.pieOfficeData[i]);
      }
      return combined;
    },

    monthNums() {
      return dates.last6MonthNums(this.month);
    },
    monthLabels() {
      return dates.last6MonthNames(this.month);
    },
    prev6MonthTitle() {
      return this.location + " previous 6 months";
    },
    breakDownTitle() {
      var month = dates.monthName(this.month);
      return this.location === audits.AllLocations ? `${month} Location Breakdown` : `${this.location} ${month} Breakdown`;
    }
  },
  watch: {
    location() {
      this.setChartData();
    },
    month() {
      this.setChartData();
    },
    activeTab() {
      this.setChartData();
    }
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    async getChartData() {
      // Load JSON
      await axios.get(this.dataUrl).then(r => {
        this.isLoading = false;
        if (r.data && r.data.auditResults) {
          this.auditResults = r.data.auditResults;

          // Issue with chart first displayed on hidden tab
          //   this.setChartData();
          // Changed to only show chart first time when tab is active
        }
      });
    },

    setChartData() {
      // Issue with chart first displayed on hidden tab, so only update if tab active
      if (this.activeTab !== this.activeTabs.Actions) return;

      let chartData = null;
      let lineData = null;

      if (this.location && this.location !== audits.AllLocations) {
        const locationData = this.auditResults.filter(d => {
          return d.location === this.location;
        });

        // Assume Data is alredy sorted - could used  list.sort((a, b) => (a.color > b.color) ? 1 : -1)
        if (locationData && locationData[0]) {
          lineData = locationData;

          // pie charts use last data for particular month
          const locationMonthData = locationData.filter(d => {
            return new Date(d.date).getMonth() === this.month;
          });
          if (locationMonthData && locationMonthData[0]) {
            chartData = locationMonthData[0];
          }
        }
      } else {
        // filter by month
        const monthData = this.auditResults.filter(d => {
          return new Date(d.date).getMonth() === this.month;
        });

        const overall = {
          data: [
            {
              type: "Customer",
              actions: [0, 0, 0]
            },
            {
              type: "Staff",
              actions: [0, 0, 0]
            },
            {
              type: "Office",
              actions: [0, 0, 0]
            }
          ]
        };
        const numAudits = monthData.length;
        if (numAudits) {
          for (var i = 0; i < monthData.length; i++) {
            overall.data[audits.AuditTypes.Customer].actions[audits.ActionStatus.Closed] +=
              monthData[i].data[audits.AuditTypes.Customer].actions[audits.ActionStatus.Closed];
            overall.data[audits.AuditTypes.Customer].actions[audits.ActionStatus.Open] +=
              monthData[i].data[audits.AuditTypes.Customer].actions[audits.ActionStatus.Open];
            overall.data[audits.AuditTypes.Customer].actions[audits.ActionStatus.Overdue] +=
              monthData[i].data[audits.AuditTypes.Customer].actions[audits.ActionStatus.Overdue];

            overall.data[audits.AuditTypes.Staff].actions[audits.ActionStatus.Closed] +=
              monthData[i].data[audits.AuditTypes.Staff].actions[audits.ActionStatus.Closed];
            overall.data[audits.AuditTypes.Staff].actions[audits.ActionStatus.Open] +=
              monthData[i].data[audits.AuditTypes.Staff].actions[audits.ActionStatus.Open];
            overall.data[audits.AuditTypes.Staff].actions[audits.ActionStatus.Overdue] +=
              monthData[i].data[audits.AuditTypes.Staff].actions[audits.ActionStatus.Overdue];

            overall.data[audits.AuditTypes.Office].actions[audits.ActionStatus.Closed] +=
              monthData[i].data[audits.AuditTypes.Office].actions[audits.ActionStatus.Closed];
            overall.data[audits.AuditTypes.Office].actions[audits.ActionStatus.Open] +=
              monthData[i].data[audits.AuditTypes.Office].actions[audits.ActionStatus.Open];
            overall.data[audits.AuditTypes.Office].actions[audits.ActionStatus.Overdue] +=
              monthData[i].data[audits.AuditTypes.Office].actions[audits.ActionStatus.Overdue];
          }

          chartData = overall;
        }

        // For line chart for ALL, we want to combine all overall, & targets across all audits for that month
        // This is handled in setLinetData where avertage for month is worked out
        lineData = this.auditResults;
      }

      if (chartData) {
        this.pieCustomerData = chartData.data[audits.AuditTypes.Customer].actions;
        this.pieStaffData = chartData.data[audits.AuditTypes.Staff].actions;
        this.pieOfficeData = chartData.data[audits.AuditTypes.Office].actions;

        this.hasData = true;
      } else {
        this.hasData = false;
      }

      // line graph
      this.setLinetData(lineData);
      this.setBreakdownData(lineData);
    },

    setLinetData(lineData) {
      // multiple records per month, get totals
      var overdueValues = [];
      var openValues = [];
      var closedValues = [];
      this.monthNums.forEach(m => {
        var monthData = lineData.filter(d => new Date(d.date).getMonth() === m);
        // get totals of audits, and totals for each audit section type
        overdueValues.push(monthData.reduce((t, a) => t + a.data.reduce((tt, dd) => dd.actions[audits.ActionStatus.Overdue], 0), 0));
        openValues.push(monthData.reduce((t, a) => t + a.data.reduce((tt, dd) => dd.actions[audits.ActionStatus.Open], 0), 0));
        closedValues.push(monthData.reduce((t, a) => t + a.data.reduce((tt, dd) => dd.actions[audits.ActionStatus.Closed], 0), 0));
      });

      this.defaultChart.chartData = {
        datasets: [
          {
            ...chartConfig.defaultDataSetOptions,
            data: overdueValues,
            borderColor: chartConfig.chartColors.default.complianceNone,
            pointBackgroundColor: chartConfig.chartColors.default.complianceNone,
            pointHoverBackgroundColor: chartConfig.chartColors.default.complianceNone,
            label: "Overdue"
          },
          {
            ...chartConfig.defaultDataSetOptions,
            borderColor: chartConfig.chartColors.default.compliancePartial,
            pointBackgroundColor: chartConfig.chartColors.default.compliancePartial,
            pointHoverBackgroundColor: chartConfig.chartColors.default.compliancePartial,
            data: openValues,
            label: "Open"
          },
          {
            ...chartConfig.defaultDataSetOptions,
            borderColor: chartConfig.chartColors.default.complianceFull,
            pointBackgroundColor: chartConfig.chartColors.default.complianceFull,
            pointHoverBackgroundColor: chartConfig.chartColors.default.complianceFull,
            data: closedValues,
            label: "Closed"
          }
        ],
        labels: this.monthLabels
      };
    },
    setBreakdownData(lineData) {
      var overdueValues = [];
      var openValues = [];
      var closedValues = [];

      // use data for particualr month
      const monthData = lineData.filter(d => {
        return new Date(d.date).getMonth() === this.month;
      });
      if (this.location === audits.AllLocations) {
        // group by location
        this.locations.forEach(l => {
          var locationData = monthData.filter(d => d.location === l);
          // get totals
          overdueValues.push(locationData.reduce((t, a) => t + a.data.reduce((tt, dd) => dd.actions[audits.ActionStatus.Overdue], 0), 0));
          openValues.push(locationData.reduce((t, a) => t + a.data.reduce((tt, dd) => dd.actions[audits.ActionStatus.Open], 0), 0));
          closedValues.push(locationData.reduce((t, a) => t + a.data.reduce((tt, dd) => dd.actions[audits.ActionStatus.Closed], 0), 0));

          this.breakDownChart.chartData = {
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: overdueValues,
                borderColor: chartConfig.chartColors.default.complianceNone,
                pointBackgroundColor: chartConfig.chartColors.default.complianceNone,
                pointHoverBackgroundColor: chartConfig.chartColors.default.complianceNone,
                label: "Overdue"
              },
              {
                ...chartConfig.defaultDataSetOptions,
                borderColor: chartConfig.chartColors.default.compliancePartial,
                pointBackgroundColor: chartConfig.chartColors.default.compliancePartial,
                pointHoverBackgroundColor: chartConfig.chartColors.default.compliancePartial,
                data: openValues,
                label: "Open"
              },
              {
                ...chartConfig.defaultDataSetOptions,
                borderColor: chartConfig.chartColors.default.complianceFull,
                pointBackgroundColor: chartConfig.chartColors.default.complianceFull,
                pointHoverBackgroundColor: chartConfig.chartColors.default.complianceFull,
                data: closedValues,
                label: "Closed"
              }
            ],
            labels: this.locations
          };
        });
      } else {
        // group by audit type
        var typeScores = [];
        this.auditTypes.forEach(t => {
          var typeScore = 0;
          monthData.forEach(a => {
            var auditScores = a.data.find(d => d.type === t);
            if (auditScores) typeScore += auditScores.actions[audits.ScoreTypes.Total];
          });
          typeScores.push(typeScore);
        });
      }
    },
    handleChartLocationClick(evt, elements) {
      if (elements.length) {
        const index = elements[0] ? elements[0]._index : -1;
        this.$emit("changeLocation", index);
      }
    }
  }
};
</script>
