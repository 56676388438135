import * as charts from "@/components/Charts/chart.config";

export default {
  AllLocations: "Overall",
  AuditTypes: { Customer: 0, Staff: 1, Office: 2 },
  AuditTypeLookup: ["Customer", "Staff", "Office"],
  ScoreTypes: { None: 0, Partial: 1, Full: 2, Total: 3 }, // Total should always be the last score
  ScoreTypeLookup: ["None", "Partial", "Full", "Total"],
  ScoreColour: [
    "", // NA
    charts.chartColors.default.complianceNone,
    charts.chartColors.default.compliancePartial,
    charts.chartColors.default.complianceFull
  ],
  ActionStatus: { Overdue: 0, Open: 1, Closed: 2 },
  ActionStatusLookup: ["Overdue", "Open", "Closed"],
  AuditStatus: { Unknown: 0, Overdue: 1, Pending: 2, InProgress: 3, Complete: 4 },
  AuditStatusLookup: ["Unknown", "Overdue", "Pending", "In Progress", "Complete"],
  AuditStatusColour: ["", "has-text-danger", "has-text-high-warning", "has-text-warning", ""]
};
